<template>
  <div class="product-require-stock-to-nhanh">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview
      v-bind:title="'Danh sách sản phẩm yêu cầu XNK sang hệ thống cũ'"
    >
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchStock"
              :suggestions="filteredOptions"
              placeholder="kho nguồn"
              :limit="10"
              @select="onSelected"
              @change="onInputChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchDestinationStock"
              :suggestions="filteredDStockOptions"
              placeholder="kho đích"
              :limit="10"
              @select="onDestinationSelected"
              @change="onSearchDestinationChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              required
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="search"
              type="text"
              placeholder="Tìm kiếm"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="stockSlipItems"
          :fields="fields"
          hover
          bordered
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(productName)="row">
            <p
              v-if="row.item.productCode"
              style="font-size: 12px; font-weight: 500; margin-bottom: 0"
            >
              ( {{ row.item.productCode }} )
            </p>
            <span
              v-text="row.item.productName"
              style="white-space: normal"
            ></span>
          </template>
          <template v-slot:cell(quantity)="row">
            <div style="text-align: right">
              <span v-text="row.item.quantity" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div
              style="
                justify-content: center;
                align-items: center;
                display: grid;
              "
            >
              <span v-text="row.item.code"></span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createBy }}</p>
          </template>

          <template v-slot:cell(approve)="row">
            <div v-if="row.item.status === 2">
              <p class="text-center text-primary mb-0">Đã duyệt</p>
              <p class="text-center">{{ row.item.approveBy }}</p>
              <p class="text-center text-primary mb-0">
                {{ row.item.approveDate }}
              </p>
            </div>
          </template>

          <template v-slot:cell(sourceWarehouseId)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.sourceWarehouseName"></span>
              <br />
              <i
                style="font-size: 1rem; color: blue"
                class="flaticon2-right-arrow"
              ></i>
              <br />
              <span v-text="row.item.destinationWarehouseName"></span>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng :
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { STOCK_SLIP_TYPE } from '@/utils/enum';
import { DP_CONFIG } from '@/utils/date';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: DP_CONFIG.date,
        end: DP_CONFIG.date,
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stockSlipItems: [],
      search: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: STOCK_SLIP_TYPE.REQUIRE_EXPORT_STOCK_TO_NHANH,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'quantity',
          label: 'SL',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'approve',
          label: 'Duyệt',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      sourceWarehouseId: null,
      optionStores: [],
      filteredOptions: [],
      limit: 10,
      searchDestinationStock: '',
      destinationWarehouseId: null,
      filteredDStockOptions: [],
      searchProduct: '',
      selectedStatus: 0,
      listStatus: [
        {
          id: 0,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Chưa duyệt',
        },
        {
          id: 2,
          name: 'Đã duyệt',
        },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [],
    };
  },
  methods: {
    fetchStore: async function () {
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionStores = stores.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredOptions = [...this.optionStores];
        this.filteredDStockOptions = [...this.optionStores];
      });
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        searchId: this.searchId,
        status: this.selectedStatus,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        searchStock: this.searchStock,
        type: 13,
        searchDestinationStock: this.searchDestinationStock,
        searchProduct: this.searchProduct,
      };

      ApiService.query('requireStocks/getAllDetail', { params })
        .then((response) => {
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          this.stockSlipItems = stockResponse.map((item, index) => {
            return {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              sourceWarehouseName: item.sourceWarehouseName,
              destinationWarehouseId: item.destinationWarehouseId,
              destinationWarehouseName: item.destinationWarehouseName,
              description: item.description,
              status: item.status,
              type: item.type,
              createBy: item.createBy,
              updateBy: item.updateBy,
              createdAt: moment(String(item.createdAt)).format('DD/MM/YYYY'),
              approveBy: item.approveBy,
              approveDate: moment(String(item.approveDate)).format(
                'DD/MM/YYYY hh:mm:ss'
              ),
              confirmBy: item.confirmBy,
              confirmDate: moment(String(item.confirmDate)).format(
                'DD/MM/YYYY hh:mm:ss'
              ),
              relateStock: item.relateStockSlipId,
              relateStockCode: item.relateStockCode,
              productName: item.productName,
              quantity: item.quantity,
              productCode: item.productCode,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    onSelected(option) {
      this.sourceWarehouseId = option.item.id;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.optionStores
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [...filteredData];
    },
    onDestinationSelected(option) {
      this.destinationWarehouseId = option.item.id;
      this.searchDestinationStock = option.item.name;
    },
    onSearchDestinationChange(text) {
      if (!text) {
        text = '';
      }
      this.searchDestinationStock = text;

      const filteredData = this.optionStores
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredDStockOptions = [...filteredData];
    },
    fetchProduct() {
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-require-to-nhanh',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Sản phẩm yêu cầu XNK sang hệ thống cũ',
        route: '/product-require-stock-to-nhanh',
      },
      { title: 'Danh sách sản phẩm yêu cầu XNK sang hệ thống cũ' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchData();
    this.fetchStore();
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
  },
};
</script>

<style lang="scss">
.product-require-stock-to-nhanh {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }
  .productCode:hover {
    text-decoration: underline;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
